import React from "react"

import "./live-symposium-icon.css"

const LiveSymposiumIcon = () => (
  <div className="icon live-symposium-icon">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 176.79 88.39"
    >
      <polygon
        class="temporary-dwellings-fill"
        points="88.39,0 0,88.39 176.79,88.39 176.79,88.39 88.4,0 "
      />
    </svg>
    <div>Live Symposium</div>
  </div>
)

export default LiveSymposiumIcon
